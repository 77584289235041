<template lang="html">
  <section class="cookie-popup">
    <div class="container">
      <div class="row">
        <h3 class="mb-4">{{ $t('storyPhonesAndCookies') }}</h3>

        <div class="col-md-8">
          <p class="mb-4">{{ $t('acceptAllCookies') }}</p>
          <!-- <div class="cookie-image">
            <img src="@/assets/images/cookie.png" alt="" />
          </div> -->
        </div>
        <div class="col-md-4">
          <!-- <div class="row"> -->
          <div class="cookie-btn">
            <button @click="goToAgreement" class="cookie-btn-1">{{ $t('viewCookiesDetail') }}</button>
            <!-- <button class="btn btn-primary">Reject cookies</button> -->
            <button class="cookie-btn-2" @click="setCookie">{{ $t('acceptCookies') }}</button>
            <!-- <button class="btn btn-primary">Reject cookies</button> -->
          </div>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </section>
  <section class="page-footer main-snow-block">
    <footer>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="snow"></div>
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <h4>{{ $t('customerService') }}</h4>
            <ul class="footer-links list-unstyled" aria-label="support pages">
              <li>
                <a href="/contact-us" aria-label="Contact Us" class="txt-bdr-link white-txt">{{
                  $t('contactUs')
                }}</a>
              </li>
              <li>
                <a href="/faq" aria-label="FAQ" class="txt-bdr-link white-txt">{{ $t('faq') }}</a>
              </li>

              <li>
                <a
                  href="/warranty-registration"
                  aria-label="Warranty Registration"
                  class="txt-bdr-link white-txt"
                  >{{ $t('warrantyRegistration') }}</a
                >
              </li>
              <li>
                <!-- download="quickStartGuide-v4.1.pdf" -->
                <a
                  :href="`/pdf/startguide_${langCode}.pdf`"
                  aria-label="Quick Start Guide"
                  class="txt-bdr-link white-txt"
                >
                  {{ $t('quickStart') }}
                </a>
              </li>
              <li>
                <a href="/setup" aria-label="User Manual" class="txt-bdr-link white-txt">{{
                  $t('userManual')
                }}</a>
              </li>
              <li>
                <a
                  href="/refund-policy"
                  aria-label="Refund Policy"
                  class="txt-bdr-link white-txt"
                  >{{ $t('refundPolicy') }}</a
                >
              </li>
              <li>
                <a
                  href="/privacy-policy"
                  aria-label="Privacy Policy"
                  class="txt-bdr-link white-txt"
                  >{{ $t('privacyPolicy') }}</a
                >
              </li>
              <li>
                <a
                  href="/licence-agreement"
                  aria-label="Licence Agreement"
                  class="txt-bdr-link white-txt"
                  >{{ $t('licenceAgreement') }}</a
                >
              </li>
              <li>
                <!-- download="quickStartGuide-v4.1.pdf" -->
                <a
                  href="/declarationOnConformity/Declaration of CE Conformity-StoryPhones_V1.pdf"
                  aria-label="Declaration of Conformity"
                  class="txt-bdr-link white-txt"
                >
                  {{ $t('conformity') }}
                </a>
              </li>
            </ul>
          </div>
          <div class="col-md-2">
            <h4>{{ $t('about') }}</h4>
            <ul class="footer-links list-unstyled">
              <li>
                <a href="/about" aria-label="Our Story" class="txt-bdr-link white-txt">{{
                  $t('ourStory')
                }}</a>
              </li>
              <li>
                <router-link
                  to="/app"
                  aria-label="StoryPhones APP"
                  class="txt-bdr-link white-txt"
                  >{{ $t('storyPhonesAPP') }}</router-link
                >
              </li>
              <li>
                <a href="/blog" aria-label="Blog" class="txt-bdr-link white-txt">{{
                  $t('blog')
                }}</a>
              </li>
            </ul>
          </div>
          <div class="col-md-3">
            <h4>{{ $t('shop') }}</h4>
            <ul class="footer-links list-unstyled">
              <li>
                <a href="/product-collections" class="txt-bdr-link white-txt">{{
                  $t('storyPhonesHeadphones')
                }}</a>
              </li>
              <li>
                <a href="/shields" class="txt-bdr-link white-txt">{{ $t('storyShields') }}</a>
              </li>
              <li>
                <a href="/custom-playlist" class="txt-bdr-link white-txt">{{
                  $t('customPlayShield')
                }}</a>
              </li>
            </ul>
          </div>
          <div class="col-md-4">
            <ul class="footer-links list-unstyled">
              Copyright © 酷音贸易（深圳）有限公司
              <li>
                <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" class="txt-bdr-link white-txt" target="_blank"
                  >粤ICP备2024227342号</a
                >
              </li>
              联系电话：0755-23772279 <br>
              联系地址： 深圳市福田区莲花街道福中福社区福中三路1006号诺德金融中心10H
            </ul>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-8">
            <h4>{{ $t('findUsAt') }}</h4>
            <ul class="social-icons mb-4 list-unstyled" aria-label="social icon">
              <div class="wrapper">
                <div class="icon">
                  <div class="tooltip">
                    <img src="@/assets/images/wechat-qrcode.jpg" alt="QR Code" class="qr-code-image" />
                  </div>
                  <span>
                      <img width="40" src="@/assets/images/wechat.png" alt="QR Code" class="qr-code-image" />
                  </span>
                </div>
                <div class="icon common">
                  <div class="tooltip">
                    <img src="@/assets/images/weibo-qrcode.jpg" alt="QR Code" class="qr-code-image" />
                  </div>
                  <span>
                    <a href="https://weibo.com/u/7744503838" target="_blank">
                      <img width="40" src="@/assets/images/weibo.png" alt="QR Code" class="qr-code-image" />
                    </a>
                  </span>
                </div>
                <div class="icon common">
                  <div class="tooltip">
                    <img src="@/assets/images/red-qrcode.jpg" alt="QR Code" class="qr-code-image" />
                  </div>
                  <span>
                    <a href="https://www.xiaohongshu.com/user/profile/5cde765f000000001803dfdd" target="_blank">
                      <img width="40" src="@/assets/images/red.jpeg" alt="QR Code" class="qr-code-image" />
                    </a>
                  </span>
                </div>
                <div class="icon common">
                  <div class="tooltip">
                    <img src="@/assets/images/tiktok-qrcode.jpg" alt="QR Code" class="qr-code-image" />
                  </div>
                  <span>
                    <a href="https://www.douyin.com/user/MS4wLjABAAAAKRq8t6QayFZA-1y4-q7NXU-YeF9EZMgk6alr1YvteJI/" target="_blank">
                      <img width="40" src="@/assets/images/tiktok.png" alt="QR Code" class="qr-code-image" />
                    </a>
                  </span>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </section>
  <div
    class="modal fade"
    id="languagePopModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="languagePopModalLabel"
    aria-hidden="true"
  >
    <LocationModal />
  </div>
</template>

<script lang="js">
import { ref, onMounted } from 'vue';
import { useCookies } from 'vue3-cookies';
import { useRouter } from 'vue-router';
import LocationModal from '@/components/partials/LocationModal.vue';

const { cookies } = useCookies({ secure: true });
export default {
  name: 'PageFooter',
  props: [],
  components: {
    LocationModal,
  },
  setup() {
    const langCode = ref('');
    const router = useRouter();

    const LocationImg = localStorage.getItem('LocationImg');
    const LocationName = localStorage.getItem('LocationName');
    langCode.value = localStorage.getItem('LangCode');
    const privacyCookie = cookies.get('privacyCookie');
    onMounted(() => {
      const cookieContainer = document.querySelector('.cookie-popup');
      if (!privacyCookie) {
        cookieContainer.style.display = 'block';
      } else {
        cookieContainer.style.display = 'none';
      }
    });
    const goToAgreement = () => {
      router.push('/licence-agreement');
    };
    const setCookie = () => {
      const cookieContainer = document.querySelector('.cookie-popup');
      const keyValue = 'Accepted';
      cookies.set('privacyCookie', keyValue);
      cookieContainer.style.display = 'none';
    };
    return {
      setCookie,
      langCode,
      goToAgreement,
      LocationImg,
      LocationName,
    };
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {

  },
};
</script>

<style scoped lang="scss"></style>
